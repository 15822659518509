<template>
    <div style="width: 80%; margin: 0 auto" class="pt-4">
        <div class="row align-center pb-3 ml-2 font-24 pt-2">
            <div class="pr-2">
                <div
                    class="row align-center justify-center primary--bg"
                    :style="`width:100px;height:48px;color:white`"
                >
                    <i class="mdi mdi-star pr-1"></i>
                    <div class="font-24">
                        {{ ChatLuong || 0 }}
                    </div>
                </div>
            </div>
            <i class="mdi mdi-star pr-2 star-style"></i>
            {{ `${tempDataThongTinXepHangVaNhanXetXe.length || 0} Đánh giá` }}
        </div>
        <div class="row justify-start align-center">
            <div
                class="row align-center justify-center mx-1 px-2"
                :style="`${
                    !item.active
                        ? 'border: 1px solid #ddd'
                        : 'border: 2px solid #3D84B8'
                };
                    border-radius: 4px;
                    height: 32px;
                    width:100px`"
                v-for="(item, index) in DanhSachNutChatLuong"
                :key="index"
                @click="locTheoChatLuong(item)"
            >
                {{ item.diem }}
                <i v-if="index > 0" class="mdi mdi-star star-style font-18"></i>
                ({{ item.soLuong }})
            </div>
        </div>
        <DxList
            :data-source="getDataThongTinXepHangVaNhanXetXe"
            height="calc(100vh - 1170px)"
            class="pt-3"
        >
            <template #item="{ data: item, index: index }">
                <div>
                    <div
                        class="row pb-2"
                        :style="`border-bottom:1px solid #ddd;${
                            index == 0
                                ? 'border-top:1px solid #ddd;padding-top:12px'
                                : ''
                        }`"
                    >
                        <div class="xs2 pr-2 text-xs-center">
                            <div
                                v-if="item.tenNguoiDanhGia"
                                class="
                                    column
                                    justify-center
                                    align-center
                                    full-height
                                "
                            >
                                <div
                                    style="
                                        width: 52px;
                                        height: 52px;
                                        border: 1px solid #ddd;
                                        border-radius: 50%;
                                    "
                                    :style="`background-image: url(${item.urlAnhDaiDien});background-repeat: round;`"
                                ></div>
                                <div style="white-space: initial !important">
                                    {{
                                        item.tenNguoiDanhGia ||
                                        "Hành khách ẩn danh"
                                    }}
                                </div>
                            </div>
                            <div
                                v-else
                                class="
                                    column
                                    justify-center
                                    align-center
                                    full-height
                                "
                            >
                                <div
                                    style="
                                        border: 1px solid #ddd;
                                        border-radius: 50%;
                                        width: 52px;
                                        height: 52px;
                                        margin: 0 auto;
                                    "
                                ></div>
                                <div style="white-space: initial !important">
                                    {{
                                        item.tenNguoiDanhGia ||
                                        "Hành khách ẩn danh"
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="xs10 pl-2">
                            <i
                                class="mdi mdi-star star-style px-1"
                                v-for="i in item.diem"
                                :key="i"
                            ></i>
                            <div class="font-12" style="opacity: 70%">
                                {{
                                    `Nhận xét vào ngày ${moment(
                                        item.ngayDanhGia
                                    ).format("DD/MM/yyyy")}`
                                }}
                            </div>
                            {{ item.noiDungDanhGia }}
                        </div>
                    </div>
                </div>
            </template>
        </DxList>
    </div>
</template>
<script>
import { DxList } from "devextreme-vue";
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        IdXe: { type: [Number, String], default: null || "" },
    },
    components: {
        DxList,
    },
    data() {
        return {
            checkChatLuong: true,
            ChatLuong: 0,
            tempDataThongTinXepHangVaNhanXetXe: [],
            DanhSachNutChatLuong: [
                {
                    id: 1,
                    diem: "Tất cả",
                    soLuong: 0,
                    active: true,
                },
                {
                    id: 2,
                    diem: 5,
                    soLuong: 0,
                    active: false,
                },
                {
                    id: 3,
                    diem: 4,
                    soLuong: 0,
                    active: false,
                },
                {
                    id: 4,
                    diem: 3,
                    soLuong: 0,
                    active: false,
                },
                {
                    id: 5,
                    diem: 2,
                    soLuong: 0,
                    active: false,
                },
                {
                    id: 6,
                    diem: 1,
                    soLuong: 0,
                    active: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters("chiTietChuyenDi", [
            "getDataThongTinXepHangVaNhanXetXe",
            "getLayThongTinXepHangTheoDiem",
            "getTabDangChon",
        ]),
        ...mapGetters("timKiemChuyenDi", ["getDialogChiTietChuyenDi"]),
    },
    methods: {
        ...mapActions("chiTietChuyenDi", ["layThongTinDanhGiaTheoDiem","layThongTinXepHangVaNhanXetXe"]),
        async locTheoChatLuong(item) {
            this.DanhSachNutChatLuong.forEach((e) => {
                e.id == item.id ? (e.active = true) : (e.active = false);
            });
            this.layThongTinDanhGiaTheoDiem(item.diem);
        },
        locTongDiemTheoNut(diem) {
            this.DanhSachNutChatLuong.forEach((c) => {
                if (c.id == 1) {
                    c.soLuong = this.tempDataThongTinXepHangVaNhanXetXe.length;
                }
                if (c.diem == diem) {
                    c.soLuong++;
                }
            });
        },
        TrungBinhDanhGia() {
            if (!this.getDataThongTinXepHangVaNhanXetXe) {
                return 0;
            }
            if (
                Array.isArray(this.getDataThongTinXepHangVaNhanXetXe) &&
                this.getDataThongTinXepHangVaNhanXetXe.length == 0
            ) {
                return 0;
            }
            let tong = this.getDataThongTinXepHangVaNhanXetXe
                .map((m) => m.diem)
                .reduce((a, b) => a + b);
            return parseFloat(
                tong / this.getDataThongTinXepHangVaNhanXetXe.length
            ).toFixed(1);
        },
        tinhToan() {
            //Tính tổng đánh giá
            this.tempDataThongTinXepHangVaNhanXetXe =
                this.getDataThongTinXepHangVaNhanXetXe;
            this.ChatLuong = this.TrungBinhDanhGia();
            this.checkChatLuong = false;
            //lọc điểm theo nút
            this.getDataThongTinXepHangVaNhanXetXe.forEach((e) => {
                this.locTongDiemTheoNut(e.diem);
            });
        },
    },
    watch: {
        getDataThongTinXepHangVaNhanXetXe: {
            handler: function () {
                if (this.getLayThongTinXepHangTheoDiem) {
                    if (this.checkChatLuong) {
                        //chi load 1 lan dau
                        this.tinhToan();
                    }
                } else {
                    this.DanhSachNutChatLuong = [
                        {
                            id: 1,
                            diem: "Tất cả",
                            soLuong: 0,
                            active: true,
                        },
                        {
                            id: 2,
                            diem: 5,
                            soLuong: 0,
                            active: false,
                        },
                        {
                            id: 3,
                            diem: 4,
                            soLuong: 0,
                            active: false,
                        },
                        {
                            id: 4,
                            diem: 3,
                            soLuong: 0,
                            active: false,
                        },
                        {
                            id: 5,
                            diem: 2,
                            soLuong: 0,
                            active: false,
                        },
                        {
                            id: 6,
                            diem: 1,
                            soLuong: 0,
                            active: false,
                        },
                    ];
                    this.tinhToan();
                }
            },
            deep: true,
        },
        getDialogChiTietChuyenDi() {
            if (this.getDialogChiTietChuyenDi && this.getTabDangChon == 2) {
                this.layThongTinXepHangVaNhanXetXe();
            }
        },
    },
};
</script>
<style scoped>
.star-style {
    color: #ffaa00;
}
</style>